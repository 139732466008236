import React from "react"
import { Helmet } from "react-helmet"

interface HeadProps {
  title?: string
}

const Head = (props: HeadProps) => {
  return (
    <Helmet titleTemplate={`BSEE UI Team | %s`}>
      <html lang="en" />
      <title>{props.title}</title>
      <link rel="icon" type="image/png" href="/icon.png" />
    </Helmet>
  )
}

export default Head
